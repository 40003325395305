<template>
  <div>
    <div class="flex justify-space-between">
      <h1>My W2 Employee Pay History</h1>
      <button class="btn btn__outlined btn__small mb-5 mt-2" @click="goBack()">Go Back<i class="fas fa-arrow-left fa-2x ml-3"></i></button>
    </div>
    <div style="overflow: auto;" class="mt-1">
      <vue-good-table
          :columns="columnsPayHistory"
          :rows="employeePayHistory.items"
           styleClass="vgt-table condensed"
          :search-options="{
            enabled: false,
            placeholder: 'Search this table',
          }"
          :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 25,
          }"
          
        >
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'payableNotes'">
              <span v-if="props.row.payableNotes">{{props.row.payableNotes[0]}}</span>
            </span>
            <span v-else-if="props.column.field == 'deposits'">
              <span v-if="props.row.deposits">{{props.row.deposits[0].destinationLabel}}</span>
            </span>
            <span v-else-if="props.column.field == 'depositsAmount'">
              <span v-if="props.row.deposits">{{props.row.deposits[0].amount.amount}}</span>
            </span>

            
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'

export default {
  name: 'employeePaystubs',
  data: () => ({
    columnsPayHistory: [
      {
        label: 'Event/Job',
        field: 'payableNotes',
      },
      {
        label: 'Stub Date',
        field: 'payStubDate',
      },
      {
        label: 'Pay Date',
        field: 'payDate',
      },
      {
        label: 'Gross Earnings',
        field: 'grossEarnings.amount',
      },
      {
        label: 'Net Earnings',
        field: 'netEarnings.amount',
      },
      {
        label: 'Deposited To',
        field: 'deposits',
      },
      {
        label: 'Deposit Amount',
        field: 'depositsAmount',
      },
    ]
  }),
  
  computed: {
    ...mapState(['currentUser', 'userProfile', 'employeePayHistory']),
  },
  components: {
    Loader,
  },
  created () {
    this.$store.dispatch('retrieveEmployeePayHistory', this.currentUser.uid)
  },
  methods: {
    goBack() {
      router.go(-1)
    },
    formatAMPM(date) {
      if (typeof date === "string") {
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        return `${hours}:${minutes} ${ampm}`;

      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;

        return strTime;
      }

      return date;
    },
  },
  beforeDestroy() {
    this.$store.dispatch('clearEmployeePayHistory')
  }
}

</script>
