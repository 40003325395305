var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex justify-space-between"},[_c('h1',[_vm._v("My W2 Employee Pay History")]),_c('button',{staticClass:"btn btn__outlined btn__small mb-5 mt-2",on:{"click":function($event){return _vm.goBack()}}},[_vm._v("Go Back"),_c('i',{staticClass:"fas fa-arrow-left fa-2x ml-3"})])]),_c('div',{staticClass:"mt-1",staticStyle:{"overflow":"auto"}},[_c('vue-good-table',{attrs:{"columns":_vm.columnsPayHistory,"rows":_vm.employeePayHistory.items,"styleClass":"vgt-table condensed","search-options":{
          enabled: false,
          placeholder: 'Search this table',
        },"pagination-options":{
          enabled: true,
          mode: 'records',
          perPage: 25,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'payableNotes')?_c('span',[(props.row.payableNotes)?_c('span',[_vm._v(_vm._s(props.row.payableNotes[0]))]):_vm._e()]):(props.column.field == 'deposits')?_c('span',[(props.row.deposits)?_c('span',[_vm._v(_vm._s(props.row.deposits[0].destinationLabel))]):_vm._e()]):(props.column.field == 'depositsAmount')?_c('span',[(props.row.deposits)?_c('span',[_vm._v(_vm._s(props.row.deposits[0].amount.amount))]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }